/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
// eslint-disable-next-line no-restricted-imports
import { Footer } from "../components/Footer/FooterV2";
import styles from "./AppointmentSuccessV2.module.scss";
import { RootState } from "app/store";
import {
  reinitialize,
  selectCalendarLinks,
  selectCreatedAppointment,
} from "features/scheduling/appointmentCreationSlice";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import successCircle from "images/success-circle.svg";
import successTick from "images/success-tick.svg";
import saveDisk from "images/save-disk.svg";
import profileDefault from "images/profile-default.svg";
import saveTime from "images/save-time.svg";
import officePlaceholder from "images/office-placeholder.svg";
import officeWhitePlaceholder from "images/office-white-placeholder.svg";
import { selectAppointmentData } from "features/appointmentDataSlice";
import { CalendarLinkModal } from "components/CalendarLinkModal";
import * as paths from "features/routing/paths";
import { LayoutV2 } from "features/layout";
import { API_DATE_FORMAT } from "api/constants";
import { FooterPortal } from "components/Footer/Footer";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullName } from "utils/doctorFullName";
import { parseStartTime } from "features/appointmentTime";
import { Button, ButtonRole } from "components/Button";
import { maskPhone } from "components/MaskedTextInput/maskPhone";

interface AppointmentReviewPathParams {
  officeId: string;
}

export const AppointmentSuccessV2: React.FC = () => {
  const appointmentData = useSelector((state: RootState) =>
    selectAppointmentData(state)
  );
  const tempappointmentData = appointmentData;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [executeOnce, setExecuteOnce] = React.useState(false);
  const createdAppointment = useSelector(selectCreatedAppointment);
  const calendarLinks = useSelector(selectCalendarLinks);
  const routeParams = useParams<AppointmentReviewPathParams>();
  let { officeId } = routeParams;
  if (
    tempappointmentData &&
    !tempappointmentData.doctor &&
    !tempappointmentData.timeSlot
  ) {
    return (
      <Redirect
        to={paths.v2appointmentStart(officeId, dayjs().format(API_DATE_FORMAT))}
      />
    );
  }
  officeId = tempappointmentData.office.id;
  React.useEffect(() => {
    if (!executeOnce && !createdAppointment && !calendarLinks) {
      setExecuteOnce(true);
      history.push(
        paths.v2appointmentStart(officeId, tempappointmentData.lastRequestUrl)
      );
    }
  }, [
    calendarLinks,
    createdAppointment,
    executeOnce,
    history,
    officeId,
    tempappointmentData.lastRequestUrl,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(reinitialize());
    };
  }, [dispatch]);

  const hyphenPlace = isMobile ? "- " : " - ";
  const vehThreeheadshot =
    tempappointmentData.doctor.offersVEH &&
    tempappointmentData.doctor.headshotUrl
      ? tempappointmentData.doctor.headshotUrl.includes("OD_Group_Final_3.png")
      : false;
  const formattedTime = parseStartTime(tempappointmentData?.timeSlot).format(
    "h:mm A"
  );
  usePageViewTracking("step-8-appointment-created", {
    doctorId: tempappointmentData?.doctor?.id,
    patientId: createdAppointment?.patientId,
    patientPhoneNumber: tempappointmentData?.patientDetails?.phoneNumber,
    patientSmsOptIn: tempappointmentData?.patientDetails?.smsOptInNotices,
    appointmentId: createdAppointment?.id,
  });
  return (
    <>
      <LayoutV2 office={tempappointmentData.office}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerImage}>
              <img
                src={successCircle}
                className={styles.imageCircle}
                alt="circle"
              />
              <img
                src={successTick}
                className={styles.imageTick}
                alt="circle"
              />
            </div>
            <span className={styles.headerText}>YOU'RE SCHEDULED!</span>
            <div className={styles.timeSlotMobile}>
              {dayjs(tempappointmentData.timeSlot.date).format(
                "ddd, MMM D, YYYY"
              )}{" "}
              at {formattedTime}
            </div>
          </div>
          <div className={styles.borderDivider}></div>
          <div className={styles.content}>
            <div>
              <div className={cx(styles.contentBox, styles.doctorPadding)}>
                <div className={styles.contentLeft}>
                  <DoctorAvatar
                    doctor={tempappointmentData.doctor}
                    size="small"
                    className={
                      vehThreeheadshot ? styles.avatar3Heads : styles.avatar
                    }
                    callFrom={"successPage"}
                    selectedOffice={tempappointmentData.office}
                  />
                </div>
                <div className={styles.contentRight}>
                  <div className={styles.doctorName}>
                    {doctorFullName(tempappointmentData.doctor)}
                  </div>
                  <div className={styles.timeSlot}>
                    {dayjs(tempappointmentData.timeSlot.date).format(
                      "ddd, MMM D, YYYY"
                    )}{" "}
                    at {formattedTime}
                  </div>
                </div>
              </div>
              <div className={styles.borderDivider}></div>
              <div className={cx(styles.contentBox, styles.doctorPadding)}>
                <div className={styles.contentLeft}>
                  <img
                    src={isMobile ? officeWhitePlaceholder : officePlaceholder}
                    className={styles.officeImg}
                    alt="Office"
                  />
                </div>
                <div className={styles.contentRight}>
                  <div className={styles.officeName}>
                    {tempappointmentData.office.displayName}
                  </div>
                  <div className={styles.officeAddress}>
                    {tempappointmentData.office.address1}, &nbsp;
                    {tempappointmentData.office.city},{" "}
                    {tempappointmentData.office.stateAbbr}&nbsp;
                    {tempappointmentData.office.formattedZipCode} <br />
                    {tempappointmentData.office.formattedPhone}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.borderDivider}></div>
            <div>
              <div className={cx(styles.contentBox, styles.patientMobile)}>
                <div className={cx(styles.contentLeft, styles.patientProfile)}>
                  <img src={profileDefault} alt="circle" />
                </div>
                <div
                  className={cx(styles.contentRight, styles.patientInfoPadding)}
                >
                  <div className={styles.patientName}>
                    {tempappointmentData.patientDetails.firstName}{" "}
                    {tempappointmentData.patientDetails.lastName}
                  </div>
                  <div className={styles.patientInfo}>
                    Date of Birth{hyphenPlace}
                    {dayjs(
                      tempappointmentData.patientDetails.dateOfBirth
                    ).format("MM/DD/YYYY")}
                  </div>
                  <div className={styles.patientInfo}>
                    Phone{hyphenPlace}
                    {maskPhone(tempappointmentData.patientDetails.phoneNumber)}
                  </div>
                  <div className={styles.patientInfo}>
                    Email
                    {hyphenPlace + tempappointmentData.patientDetails.email}
                  </div>
                </div>
              </div>
              <div className={styles.borderDivider}></div>
              <div className={cx(styles.contentBox, styles.checkInPad)}>
                <div className={styles.contentLeft}>
                  <img
                    src={saveTime}
                    className={styles.saveTimeIcon}
                    alt="Office"
                  />
                </div>
                <div className={styles.contentRight}>
                  <div className={styles.saveTime}>Save Time In The Office</div>
                  <div className={styles.checkButton}>
                    <Button
                      text="Check In Online"
                      onClick={(ev) => {
                        history.push(paths.checkInHome());
                        ev.stopPropagation();
                      }}
                      role={ButtonRole.Standard}
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.footer}>
          <div className={styles.buttonContainer}>
            <Button
              text="Add to Calendar"
              onClick={(ev) => {
                setModalOpen(true);
                ev.stopPropagation();
              }}
              role={ButtonRole.Ghost}
              size="large"
            />
          </div>
          <div className={styles.wantAnother}>
            <a
              href={
                window.location.origin +
                "/appointments/" +
                tempappointmentData.office.id +
                "/start"
              }
            >
              Need to schedule another exam?
            </a>
          </div>
          <div className={styles.confirmContainer}>
            <img src={saveDisk} className={styles.saveDisk} alt="saveDisk" />A
            confirmation email has been sent to you with a link to your{" "}
            <b>Patient Portal</b>.<br />
            Manage your appointment and check in online through your Patient
            Portal.
          </div>
        </div>
        <CalendarLinkModal
          onRequestClose={() => setModalOpen(false)}
          isModalOpen={isModalOpen}
          links={calendarLinks}
        />
        <Footer formattedPhone={tempappointmentData.office.formattedPhone} />
        <FooterPortal />
      </LayoutV2>
    </>
  );
};
