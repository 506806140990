import { RedirectQueryParams } from "./AuthorizedRouter";
import { toQueryString } from "./query";
import { SchedulingRouteParams } from "types/schedulingRouteParams";

export interface VerifyDemographicsRouteParams {
  token: string;
}

export interface EnterTwoFactorCodeRouteParams {
  phoneNumberId: string;
}

export interface EnterTwoFactorCodeQuery {
  query?: {
    finalPhoneDigits: string;
  };
}

type RequiredParams<Params, Keys extends keyof Params> = Required<
  Pick<Params, Keys>
>;

export type RequiredSchedulingRouteParams<
  Keys extends keyof SchedulingRouteParams
> = RequiredParams<SchedulingRouteParams, Keys>;

export interface AvailabilityQueryParams {
  query?: { date?: string; doctorId?: string };
}

export interface TokenRouteParams {
  token: string;
}

export interface OfficeRouteParams {
  officeId: string;
}

export interface OrderRouteParams {
  orderNum: string;
}

export interface CommunicationPreferencesRouteParams {
  patientId: string;
}

export const LOCATIONS_PAGE_URL = "https://locations.myeyedr.com/";
export const LOCATIONS_PAGE_SEARCH_URL =
  "https://locations.myeyedr.com/search?q=";
export const LOCATIONS_PAGE_VARIANT_SEARCH_URL =
  "https://locations.myeyedr.com/search?variant=true";
export const GET_RX_URL = "https://getrx.myeyedr.com/";

export const root = (): string => "/";

// old URLs
export const oldManageAppointmentFragment = "/manage_my_appointment";
const oldCurrentAppointmentFragment = "/current";
const oldPatientDashboardFragment = "/patient_dashboard";

export const old = {
  verifyDemographics: ({ token }: VerifyDemographicsRouteParams): string =>
    `${oldManageAppointmentFragment}/authorize/${token}`,
  appointmentAuthorizationFailed: (): string =>
    `${oldManageAppointmentFragment}/authorize/failed`,
  magicLinkLanding: ({ token }: TokenRouteParams): string =>
    `${oldManageAppointmentFragment}/${token}`,
  currentAppointment: (): string =>
    `${oldManageAppointmentFragment}${oldCurrentAppointmentFragment}`,
  patientDashboardLogin: (): string => `${oldPatientDashboardFragment}/login`,
  communicationPreferences: ({
    patientId,
  }: CommunicationPreferencesRouteParams): string =>
    `/communication_preferences/${patientId}`,
};
// end old URLs

export const portalFragment = "/my-account";

// portal urls
export const verifyDemographics = ({
  token,
  query,
}: VerifyDemographicsRouteParams & RedirectQueryParams): string =>
  `${portalFragment}/verify/${token}${toQueryString(query)}`;

export const appointmentAuthorizationFailed = (): string =>
  `${portalFragment}/verify/failed`;

export const chooseTwoFactorMethod = ({ query }: RedirectQueryParams): string =>
  `${portalFragment}/login/2fa/start${toQueryString(query)}`;

export const enterTwoFactorCode = ({
  phoneNumberId,
  query,
}: RedirectQueryParams &
  EnterTwoFactorCodeRouteParams &
  EnterTwoFactorCodeQuery): string =>
  `${portalFragment}/login/2fa/${phoneNumberId}/verify${toQueryString(query)}`;

export const magicLinkLanding = ({ token }: TokenRouteParams): string =>
  `${portalFragment}/login/${token}`;

export const portalHome = (): string => `${portalFragment}/home`;

export const portalLogin = (): string => `${portalFragment}/login`;

export const communicationPreferences = ({
  patientId,
}: CommunicationPreferencesRouteParams): string =>
  `${portalFragment}/communication-preferences/${patientId}`;

export const checkInBase = (): string => `${portalFragment}/check-in`;

export const checkInHome = (): string => `${portalFragment}/check-in/home`;

export const checkInAboutYou1 = (): string =>
  `${portalFragment}/check-in/about-you-1`;

export const checkInAboutYou2 = (): string =>
  `${portalFragment}/check-in/about-you-2`;

export const reviewYourAddress = (): string =>
  `${portalFragment}/check-in/review-your-address`;

export const checkInInsurance = (): string =>
  `${portalFragment}/check-in/insurance`;

export const checkInInsuranceReview = (): string =>
  `${portalFragment}/check-in/insurance/review`;

export const reviewYourInsurance = (): string =>
  `${portalFragment}/check-in/existing-insurance`;

export const checkInInsuranceSkip = (): string =>
  `${portalFragment}/check-in/insurance/none`;

export const checkInPrescriptions = (): string =>
  `${portalFragment}/check-in/prescriptions`;

export const v2appointmentStart = (officeId: string, date: string): string =>
  `/appointments/${officeId}/start?date=${date}`;

export const v2appointmentReview = (officeId: string): string =>
  `/appointments/${officeId}/review`;

export const v2appointmentSuccess = (officeId: string): string =>
  `/appointments/${officeId}/success`;

// scheduling urls
export const appointmentBookingStart = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/V1appointments/${officeId}/start`;

export const appointmentsSelectType = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/V1appointments/${officeId}/select-type`;

export const appointmentsSelectReturnStatus = ({
  officeId,
  examType,
}: RequiredSchedulingRouteParams<"officeId" | "examType">): string =>
  `/V1appointments/${officeId}/${examType}/select-return-status`;

export const appointmentsAvailableAppointments = ({
  officeId,
  examType,
  patientReturnStatus,
  query,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
> &
  AvailabilityQueryParams): string => {
  return `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/availability${toQueryString(
    query
  )}`;
};

export const appointmentsAvailableAppointmentsDoctor = ({
  officeId,
  examType,
  patientReturnStatus,
  doctorId,
  query,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus" | "doctorId"
> &
  AvailabilityQueryParams): string => {
  return `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/doctor-by-week/${doctorId}${toQueryString(
    query
  )}`;
};

export const appointmentsSummary = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
> &
  SchedulingRouteParams): string =>
  `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/summary`;

export const appointmentsAccountSetup = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/account-setup`;

export const appointmentsSuccess = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/success`;

export const appointmentsSuccessNew = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/V1appointments/${officeId}/${examType}/${patientReturnStatus}/successNew`;

export const appointmentsSlotTaken = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/appointments/${officeId}/${examType}/${patientReturnStatus}/slot-taken`;

export const appointmentsFailure = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/v1appointments/${officeId}/${examType}/${patientReturnStatus}/error`;

export const appointmentsReview = ({
  officeId,
  examType,
  patientReturnStatus,
}: RequiredSchedulingRouteParams<
  "officeId" | "examType" | "patientReturnStatus"
>): string =>
  `/v1appointments/${officeId}/${examType}/${patientReturnStatus}/review`;

export const appointmentsFailureV2 = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/appointments/${officeId}/error`;

export const appointmentSuccessV2 = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/appointments/${officeId}/success`;

export const appointmentSuccessNewV2 = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/appointments/${officeId}/successNew`;

export const appointmentsReviewV2 = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/appointments/${officeId}/review`;

export const appointmentBookingStartV2 = ({
  officeId,
}: RequiredSchedulingRouteParams<"officeId">): string =>
  `/appointments/${officeId}/start`;

export const offices = (): string => "/offices";

export const componentCatalog = (): string => "/component-catalog";

export const OrderTrackerParams = ({
  orderUID,
}: RequiredSchedulingRouteParams<"orderUID">): string =>
  `/order_status/${orderUID}`;
