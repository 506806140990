import React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import { LoadingToast } from "../AppointmentReview/LoadingToast";
// eslint-disable-next-line no-restricted-imports
import {
  RescheduleAppointmentModal,
  rescheduleAppointmentModalAria,
} from "../AppointmentReview/RescheduleAppointmentModal";
// eslint-disable-next-line no-restricted-imports
import { toTel } from "../utils/toTel";
// eslint-disable-next-line no-restricted-imports
import { Footer } from "../components/Footer/FooterV2";
import styles from "./AppointmentReviewV2.module.scss";
import { RootState, AppDispatch } from "app/store";
import { LayoutV2 } from "features/layout";
import { externalRedirect } from "utils/externalRedirect";
import { API_DATE_FORMAT } from "api/constants";
import * as paths from "features/routing/paths";
import {
  setIsEditForm,
  setEditSection,
  setAppointmentData,
  setVersion,
  selectAppointmentData,
} from "features/appointmentDataSlice";
import { CreateAppointmentArgs } from "api/createAppointmentRequest";
import { PatientReturnStatus } from "types/patientReturnStatus";
import {
  createAppointment,
  selectAppointmentBookedStatus,
  CreateAppointmentResult,
  selectCreatedAppointment,
} from "features/scheduling/appointmentCreationSlice";
import { useTrackEvent } from "hooks/useTrackEvent";
import { DoctorAvatar } from "components/DoctorAvatar";
import { FooterPortal } from "components/Footer/Footer";
import { today } from "features/scheduling/utils/today";
import { Button } from "components/Button";
import { doctorFullName } from "utils/doctorFullName";
import { parseStartTime } from "features/appointmentTime";
import { Modal } from "components/Modal";
import { maskPhone } from "components/MaskedTextInput/maskPhone";

interface AppointmentReviewPathParams {
  officeId: string;
}

declare let REACT_APP_ENV: string | undefined;
export const AppointmentReviewV2: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const routeParams = useParams<AppointmentReviewPathParams>();
  let { officeId } = routeParams;
  const [submitLocked, setSubmitLocked] = React.useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = React.useState(false);
  const appointmentData = useSelector((state: RootState) =>
    selectAppointmentData(state)
  );
  const [executeOnce, setExecuteOnce] = React.useState(false);

  const tempappointmentData = appointmentData;

  if (
    tempappointmentData &&
    !tempappointmentData.doctor &&
    !tempappointmentData.timeSlot
  ) {
    return <Redirect to={paths.v2appointmentStart(officeId, dayjs().format(API_DATE_FORMAT))} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const history = useHistory();
  officeId = tempappointmentData.office.id;
  const officeUid = tempappointmentData.office.Uid;
  const patient = tempappointmentData.patientDetails;
  const examType = tempappointmentData.eyeExam;
  const providerId = tempappointmentData.providerId;
  const formattedPhone = tempappointmentData.formattedPhone;
  const patientReturnStatus = tempappointmentData.patientType;
  const appointmentBookedStatus = useSelector(selectAppointmentBookedStatus);
  const createdAppointment = useSelector(selectCreatedAppointment);
  const lastRequestUrl = tempappointmentData.lastRequestUrl;
  const formattedTime = parseStartTime(tempappointmentData?.timeSlot).format(
    "h:mm A"
  );
  const { doctorId, date, startTime, endTime } = tempappointmentData.timeSlot;
  const scheduleAppointment = (overrides?: Partial<CreateAppointmentArgs>) => {
    setSubmitLocked(true);

    dispatch(
      createAppointment({
        officeId,
        officeUid,
        providerId,
        patient,
        doctorId,
        date,
        startTime,
        endTime,
        examType,
        patientReturnStatus: patientReturnStatus as PatientReturnStatus,
        ...overrides,
      })
    );
  };

  React.useEffect(() => {
    if (!executeOnce && createdAppointment) {
      history.push(paths.v2appointmentStart(officeId, lastRequestUrl));
      setExecuteOnce(true);
    }
    trackEvent("step-7-account-created", {
      location: officeId,
      selected_type: examType,
      patient_type: patientReturnStatus,
      doctorId: doctorId,
    });
  }, [
    createdAppointment,
    lastRequestUrl,
    executeOnce,
    history,
    officeId,
    trackEvent,
    examType,
    patientReturnStatus,
    doctorId,
  ]);

  const onClickSlot = () => {
    if (submitLocked) {
      return false;
    }
    dispatch(setIsEditForm(true));
    dispatch(setEditSection("timeSlot"));
    dispatch(
      setAppointmentData({
        status: true,
        office: tempappointmentData.office,
        doctor: undefined,
        timeSlot: undefined,
        patientType: patientReturnStatus,
        eyeExam: examType,
        patientDetails: patient,
        lastRequestUrl: tempappointmentData.lastRequestUrl,
      })
    );
    history.push(
      paths.v2appointmentStart(officeId, tempappointmentData.lastRequestUrl)
    );
  };

  const onClickPatient = () => {
    if (submitLocked) {
      return false;
    }
    dispatch(setIsEditForm(true));
    dispatch(setEditSection("patientDetails"));
    history.push(
      paths.v2appointmentStart(officeId, tempappointmentData.lastRequestUrl)
    );
  };

  const onClickOffice = () => {
    if (submitLocked) {
      return false;
    }
    const appEnv = typeof REACT_APP_ENV !== "undefined" ? REACT_APP_ENV : "";
    if (appEnv === "undefined") {
      externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
    } else {
      externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
      // history.push(paths.offices());
    }
    return false;
  };

  React.useEffect(() => {
    dispatch(setVersion(2));
    switch (appointmentBookedStatus) {
      case CreateAppointmentResult.Created:
        //history.push(paths.appointmentSuccessV2(officeId));
        if (createdAppointment?.isNewPatient == "yes") {
          history.push(paths.appointmentSuccessNewV2(routeParams));
        } else {
          history.push(paths.appointmentSuccessV2(routeParams));
        }
        trackEvent("step-7-account-created", {
          location: officeId,
          selected_type: examType,
          patient_type: patientReturnStatus,
          doctorId: doctorId,
        });
        break;
      case CreateAppointmentResult.SlotTaken:
        console.log(CreateAppointmentResult);
        history.push(
          paths.appointmentsSlotTaken({
            officeId: officeId,
            examType: examType,
            patientReturnStatus: patientReturnStatus,
          })
        );
        break;
      case CreateAppointmentResult.Failed:
        history.push(paths.appointmentsFailureV2(officeId));
        break;
      case CreateAppointmentResult.DuplicateAppointment:
        setSubmitLocked(false);
        setShowRescheduleModal(true);
        break;
    }
    dispatch(setIsEditForm(false));
  }, [
    history,
    appointmentBookedStatus,
    setShowRescheduleModal,
    dispatch,
    officeId,
    createdAppointment?.isNewPatient,
    routeParams,
    trackEvent,
    examType,
    patientReturnStatus,
    doctorId,
  ]);
  return (
    <>
      <LoadingToast isLoading={submitLocked} />
      <Modal
        aria={rescheduleAppointmentModalAria}
        isOpen={showRescheduleModal}
        onRequestClose={() => setShowRescheduleModal(false)}
        closeOnOverlayClick={false}
        className={styles.modal}
      >
        <RescheduleAppointmentModal
          setShowRescheduleModal={setShowRescheduleModal}
          submitLocked={submitLocked}
          scheduleAppointment={scheduleAppointment}
        />
      </Modal>
      <LayoutV2 office={tempappointmentData.office}>
        <h1 className={styles.headline}>Review Appointment Details</h1>
        <div className={styles.borderDivider}></div>
        <div className={styles.firstContainer}>
          <div className={styles.containerLeft}>
            <div className={styles.headerSection}>
              <div className={styles.heading}>Selected Office</div>
              <div className={styles.editContainer}>
                <div aria-hidden="true" onClick={onClickOffice}>
                  Change
                </div>
              </div>
            </div>
            <div className={styles.contentSection}>
              <div className={styles.officeName}>
                {tempappointmentData.office.displayName}
              </div>
              <div className={styles.officeAddress}>
                {tempappointmentData.office.address1},&nbsp;
                {tempappointmentData.office.city},{" "}
                {tempappointmentData.office.stateAbbr}&nbsp;
                {tempappointmentData.office.formattedZipCode}
                <br />
                <a href={toTel(tempappointmentData.office.formattedPhone)}>
                  {tempappointmentData.office.formattedPhone}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.borderDivider}></div>
          <div className={styles.containerRight}>
            <div className={styles.headerSection}>
              <div className={styles.heading}>selected doctor and time</div>
              <div className={styles.editContainer}>
                <div aria-hidden="true" onClick={() => onClickSlot()}>
                  Change
                </div>
              </div>
            </div>
            <div className={styles.contentSection}>
              <div className={styles.doctorContainer}>
                <DoctorAvatar
                  doctor={tempappointmentData.doctor}
                  size="small"
                  selectedOffice={tempappointmentData.office}
                />
                <div className={styles.doctorDetails}>
                  <div className={styles.officeName}>
                    {doctorFullName(tempappointmentData.doctor)}
                  </div>
                  <div className={styles.officeAddress}>
                    {dayjs(tempappointmentData.timeSlot.date).format(
                      "ddd, MMM D, YYYY"
                    )}{" "}
                    at {formattedTime}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.patientDesktop}>
          <div className={cx(styles.headerSection, styles.patientEdit)}>
            <div className={styles.heading}>Patient Details</div>
            <div className={styles.editContainer}>
              <div aria-hidden="true" onClick={() => onClickPatient()}>
                Edit
              </div>
            </div>
          </div>
          <div className={styles.contentSection}>
            <div className={styles.patientOne}>
              <div className={styles.firstName}>
                <div className={styles.officeName}>First Name</div>
                <br />
                <div className={styles.officeAddress}>
                  {tempappointmentData.patientDetails.firstName}
                </div>
              </div>
              <div className={styles.lastName}>
                <div className={styles.officeName}>Last Name</div>
                <br />
                <div className={styles.officeAddress}>
                  {tempappointmentData.patientDetails.lastName}
                </div>
              </div>
            </div>
            <div className={styles.patientTwo}>
              <div className={styles.phoneNumber}>
                <div className={styles.officeName}>Phone Number</div>
                <br />
                <div className={styles.officeAddress}>
                  {maskPhone(tempappointmentData.patientDetails.phoneNumber)}
                </div>
              </div>
              <div className={styles.email}>
                <div className={styles.officeName}>Email Address</div>
                <br />
                <div className={styles.officeAddress}>
                  {tempappointmentData.patientDetails.email}
                </div>
              </div>
              <div className={styles.dob}>
                <div className={styles.officeName}>Date of Birth</div>
                <br />
                <div className={styles.officeAddress}>
                  {dayjs(tempappointmentData.patientDetails.dateOfBirth).format(
                    "MM/DD/YYYY"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.patientMobile}>
          <div className={cx(styles.headerSection, styles.patientEdit)}>
            <div className={styles.heading}>Patient Details</div>
            <div className={styles.editContainer}>
              <div aria-hidden="true" onClick={() => onClickPatient()}>
                Edit
              </div>
            </div>
          </div>
          <div className={styles.contentSection}>
            <div className={styles.nameContainer}>
              {tempappointmentData.patientDetails.firstName}{" "}
              {tempappointmentData.patientDetails.lastName}
            </div>
            <div className={styles.patientContainer}>
              Date of Birth-{" "}
              {dayjs(tempappointmentData.patientDetails.dateOfBirth).format(
                "MM/DD/YYYY"
              )}
            </div>
            <div className={styles.patientContainer}>
              Phone- {maskPhone(tempappointmentData.patientDetails.phoneNumber)}
            </div>
            <div className={styles.patientContainer}>
              Email- {tempappointmentData.patientDetails.email}
            </div>
          </div>
        </div>
        {!tempappointmentData.office.supportsMedicaid && (
          <div className={styles.medicaid}>
            This office does not accept Medicaid coverage. If you wish to use
            Medicaid for your visit please{" "}
            <a href="https://locations.myeyedr.com/">
              {" "}
              select another MyEyeDr. location{" "}
            </a>
          </div>
        )}
        <div className={cx(styles.pdetailsLin)}></div>
        <div className={styles.buttonClass}>
          <Button
            // onClick={handleSubmit(onNext)}
            text="Schedule Exam"
            onClick={() => {
              scheduleAppointment();
            }}
            disabled={submitLocked}
            loading={submitLocked}
          />
        </div>
        <div className={cx(styles.pdetailsLin)}></div>
        <Footer formattedPhone={formattedPhone} />
        <FooterPortal />
      </LayoutV2>
    </>
  );
};
